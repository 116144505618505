import { Directive, ElementRef, Renderer2, computed, effect, inject, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import type { ClassValue } from 'clsx';

@Directive({
  selector: '[btoProgressIndicator],brn-progress-indicator[bto]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class HlmProgressIndicatorDirective {
  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);
  value = input<string | number | null>(0);
  animated = input(true);

  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    hlm(
      'inline-flex transform-gpu h-full w-full flex-1 bg-primary' + (this.animated() ? ' transition-all' : ''),
      this.userClass()
    )
  );

  constructor() {
    effect(() => {
      // using renderer directly as hostbinding is one change detection cycle behind
      const value = this.value();
      const numValue = value !== null ? +value : null;
      this._renderer.setStyle(this._element.nativeElement, 'transform', `translateX(-${100 - +(numValue ?? 0)}%)`);
      if (numValue === 0) {
        this._renderer.addClass(this._element.nativeElement, 'animate-indeterminate');
      } else {
        this._renderer.removeClass(this._element.nativeElement, 'animate-indeterminate');
      }
    });
  }
}
